<template>
  <div class="page" id="page">
    <div class="mine">
      <div class="top" style="margin-top: 80px">
        <div class="title">
          <div class="lent"></div>
          <div class="title-text">联系我们</div>
          <div class="lent"></div>
        </div>
        <p class="title-bottom-font">CONTACT US</p>
      </div>

      <p class="phone">联系热线：400-826-8098</p>

      <p class="cor">宇普西隆（杭州）信息技术有限公司</p>

      <p class="cor">联系地址：浙江省杭州市创伟科技园A座</p>

      <p class="line"></p>

      <div class="ewm">
        <div class="box-rwm">
          <img class="code_img" src="../assets/code/andriod_code.png" />
          <div class="txt">未来+APP-android</div>
        </div>

        <div class="box-rwm">
          <img class="code_img" src="../assets/code/ios_code.png" />
          <div class="txt">未来+APP-iOS</div>
        </div>
        <div class="box-rwm">
          <img class="code_img" src="../assets/code/miniProgram_code.png" />
          <div class="txt">未来+APP-小程序</div>
        </div>

        <div class="box-rwm">
          <img class="code_img" src="../assets/code/kf_code.png" />
          <div class="txt">客服经理</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  watch: {}
};
</script>
<style scoped lang="less">
.page {
  width: 100%;
  text-align: center;
  .mine {
    width: 70%;
    margin: auto;
  }

  .top {
    width: 300px;
    margin: auto;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .lent {
        width: 48px;
        height: 2px;
        background-color: #333333;
        // border: 1px solid #333333;
      }
      .title-text {
        font-weight: bold;
        color: #333333;
        font-size: 32px;
      }
    }
    .title-bottom-font {
      font-weight: 400;
      color: #999999;
      font-size: 16px;
    }
  }
  .phone {
    font-size: 34px;
    font-weight: 400;
    color: #333333;
    margin-top: 130px;
    margin-bottom: 50px;
  }
  .cor {
    font-size: 18px;
    font-weight: 400;
    color: #333333;
  }
  .line {
    width: 100%;
    height: 2px;
    background-color: #ededed;
    margin: 30px 0 50px 0;
  }
  .ewm {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 300px;

    .box-rwm {
      width: 249px;
      height: 249px;
      // background: #ffffff;
      // box-shadow: 0px 12px 38px 0px rgba(0, 0, 0, 0.24);
      // border-radius: 17px;

      .code_img {
        width: 319px;
        height: 319px;
        transform: translate(-35px, -20px);
      }
      .txt {
        font-size: 21px;
        font-weight: 400;
        color: #333333;
        margin-top: -40px;
      }
    }
  }
}
</style>
